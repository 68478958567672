import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import ProjectPreviewList from "../components/project-preview-list";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const query = graphql`
  query ProjectsQuery {
    projects: allSanityProject {
      nodes {
        id
        title
        mainImage {
          alt
          asset {
            _id
          }
          crop {
            top
            right
            left
            bottom
            _type
            _key
          }
          hotspot {
            _key
            _type
            height
            width
            y
            x
          }
        }
        slug {
          current
        }
      }
    }
  }
`;

const Projecten = ({ data }) => (
  <Layout>
    <SEO title="Projecten" />
    <ProjectPreviewList heading="Projecten" projects={data.projects.nodes} />
  </Layout>
);

Projecten.propTypes = {
  data: PropTypes.object,
};

export default Projecten;
